@media screen and (min-width: 48em) {
  .c-clients-banner__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.c-clients-banner__wrapper {
  flex-wrap: nowrap;
}
